<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_provider"
              :items="providers"
              item-text="nombre"
              item-value="codigo"
              label="Escoge un Cliente"
              @change="getByCustomer"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
    </v-container>

      <!-- :items="currentItems" -->
    <v-data-table
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="id"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
      
    >
      <template v-slot:[`item.uva`]="{ item }">
          <div v-if="item.uva_>0">
            <!-- {{ parseFloat(item.precio * item.cant).toFixed(2) }} -->
            {{ item.uva_ }} 
              <v-icon small> fas fa-arrow-circle-right </v-icon> 
            {{ item.uva }}
          </div>
      </template>
      <template v-slot:[`item.ps`]="{ item }">
          <div v-if="item.ps_>0">
            <!-- {{ parseFloat(item.precio * item.cant).toFixed(2) }} -->
            {{ item.ps_ }} 
              <v-icon small> fas fa-arrow-circle-right </v-icon> 
            {{ item.ps }}
          </div>
      </template>
      <template v-slot:[`item.pm`]="{ item }">
          <div v-if="item.pm_>0">
            <!-- {{ parseFloat(item.precio * item.cant).toFixed(2) }} -->
            {{ item.pm_ }} 
              <v-icon small> fas fa-arrow-circle-right </v-icon> 
            {{ item.pm }}
          </div>
      </template>
      <template v-slot:[`item.ph`]="{ item }">
          <div v-if="item.ph_>0">
            <!-- {{ parseFloat(item.precio * item.cant).toFixed(2) }} -->
            {{ item.ph_ }} 
              <v-icon small> fas fa-arrow-circle-right </v-icon> 
            {{ item.ph }}
          </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                class="mr-2" 
                v-bind="attrs"
                v-on="on"
                color="primary"
                @click="addList(item)">fas fa-list-alt</v-icon>
              </template>
              <span>Agregar Lista de Productores</span>
            </v-tooltip>
            
      </template>
      <template v-slot:top>
          <v-dialog 
          v-model="dialogList" 
          max-width="900px"
          >
            <!-- <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template> -->
            <v-card>
              
              <v-card-title>
                <v-col cols="12" sm="12" md="4">
                <span class="headline">Productores</span>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                <span class="title" >Total Oro: {{ numberWithCommas(total_oro) }}</span>
                </v-col>
                  
                <v-spacer></v-spacer>
                <div>

              <v-btn
                style="right:10px; top:10px;"
                icon
                color="error"
                fab
                absolute
                @click="dialogList = false"
              >
                <v-icon dark>fa-times</v-icon>
              </v-btn>

                </div>
              </v-card-title>

              <v-card-text>
                <v-container>

                  <v-data-table
                    :headers="headers_customers"
                    :items="customers"
                    sort-by="date"
                    sort-desc
                    :loading="loading"
                    loading-text="Consultando datos... Espere por favor"
                  >
                  <!-- BEGIN MODAL CHILD -->
                    <template v-slot:top>
                      <v-toolbar flat color="white">
                        <v-spacer></v-spacer>

                        <v-dialog v-model="dialog" max-width="600px">
                          <template v-slot:activator="{ on }">
                              <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-form ref="form" v-model="valid">
                                  <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-text-field
                                        v-model="editedItem.name"
                                        label="Nombre"
                                        :rules="editedItem.nameRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-text-field
                                        v-model="editedItem.oro"
                                        label="Cantidad (ORO)"
                                        :rules="editedItem.oroRules"
                                        type="number"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="12">
                                      <v-textarea
                                        solo
                                        auto-grow
                                        outlined
                                        rows="3"
                                        v-model="editedItem.comment"
                                        label="Observación"
                                      ></v-textarea>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-dialog v-model="dialogDelete" max-width="450">
                        <v-card>
                          <v-card-title>
                            <span>¿Desea eliminar este registro?</span>
                            <v-spacer></v-spacer>
                          </v-card-title>
                          <v-card-text>
                            <div> <b>Productor:</b> {{ editedItem.name }} </div>
                            <div> <b>Cantidad:</b> {{ editedItem.oro }} </div>
                          </v-card-text>
                          <v-card-actions>
                            <!-- <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                            <v-btn color="green darken-1" text @click="eliminar">Aceptar</v-btn> -->

                            <v-row no-gutters style="flex-wrap: nowrap;">
                            <v-btn
                              class="flex-grow-1 flex-shrink-0"
                              large
                              rounded
                              outlined
                              color="warning"
                              @click="close"
                              >Cancelar</v-btn
                            >
                            <v-btn
                              class="flex-grow-1 flex-shrink-0"
                              large
                              rounded
                              color="error"
                              @click="eliminar"
                              >Aceptar</v-btn
                            >
                          </v-row>

                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon small class="mr-2" color="success" @click="editItem(item)">fas fa-edit</v-icon>
                      <v-icon small color="red" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
                    </template>

                    <template slot="body.append">
                      <tr class="gray--text">
                        <!-- <th class="title"></th> -->
                        
                        <th colspan="8" class="title">
                        <v-row class="pt-2">
                          <v-col cols="12" sm="12" md="4">
                            Pendiente: {{ numberWithCommas((total_oro - parseFloat(sumField('oro'))).toFixed(2)) }}
                          </v-col>
                          <v-col offset-md="4" cols="12" sm="12" md="4">
                            Total: {{ numberWithCommas((parseFloat(sumField('oro'))).toFixed(2)) }}
                          </v-col>
                        </v-row>
                        </th>
                        
                      </tr>
                    </template>

                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
      </template>


    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';

export default {
  name: "calculatePartner",
  data: () => ({
    dialog: false,
    dialogList: false,
    dialogFactor: false,
    samplings: [],
    currentItems: [],
    items: [],
    providers: [],
    types: [],
    customers: [],
    damages: [],
    processes: [],
    damages: [],
    process: "",
    type: "",
    total_oro: "",
    id_provider: null,
    id_partner: null,
    dates: [],
    modal: false,
    loader: null,
    loading3: false,
    dialogDelete: false,
    search: "",
    loading: false,
    titulo: "Cálculo de Retenciones para Mayoristas",
    headers: [
      // { text: "C", align: "left", value: "codprov" },
      { text: "Nombre", value: "productor" },
      { text: "UVA (ORO)", value: "uva" },
      { text: "P. Seco (ORO)", value: "ps" },
      { text: "P. Mojado (ORO)", value: "pm" },
      { text: "P. Húmedo (ORO)", value: "ph" },
      { text: "TOTAL ORO", value: "oro" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    headers_customers: [
      { text: "Fecha", align: "left", value: "date"},
      { text: "Nombre", align: "left", value: "name" },
      { text: "Observación", align: "left", value: "comment" },
      { text: "Total", value: "oro" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    editedIndex: -1,

    valid: true,

    editedItem: {
      name: "",
      oro: "",
      comment: "",
      nameRules: [v => !!v || "Escribe un nombre"],
      oroRules: [v => !!v || "Escribe la cantidad en oro"],
    },
    defaultItem: {
      name: "",
      oro: "",
      comment: "",
      oroRules: [v => !!v || "Escribe la cantidad en oro"],
    },
  }),

  computed: {
    
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close();
    // },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      let datos;
      await axios.get(this.db + 'retentions_partner',
      {
        headers: this.headers_db
      })
      .then(response => {
          datos = response.data.data;
          this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
      })
      
      let id_productor = null;
      let data = [];

        datos.forEach(item => {
        let element = this.types.filter(doc => {
          return doc.codigo == item.codigo;
        });
        
        let factor  = (element[0].factor / 100);
        if (item.codigo == 2) {
          // console.log(item.cant, element[0].factor, factor);
          item.uva = (parseFloat(item.cant) * parseFloat(factor)).toFixed(2);
          item.uva_ = parseFloat(item.cant);
        }
        else if (item.codigo == 4) {
          item.ps = (parseFloat(item.cant) * parseFloat(factor)).toFixed(2);
          item.ps_ = parseFloat(item.cant);
        }
        else if(item.codigo == 3){
          item.ph = (parseFloat(item.cant) * parseFloat(factor)).toFixed(2);
          item.ph_ = parseFloat(item.cant);
        }
        else if(item.codigo == 7){
          item.pm = (parseFloat(item.cant) * parseFloat(factor)).toFixed(2);
          item.pm_ = parseFloat(item.cant);
        }
        // console.log(id_productor, item.codprov, item.oro);
        if (id_productor == item.codprov || id_productor == null) {
          if (id_productor == null) {
            data.push(item);
          }
        }
        else {
          data.push(item);
        }
        id_productor = item.codprov;
      });

      datos.forEach(element => {
        data.forEach(item => {
          if (element.codprov == item.codprov) {
            console.log(parseFloat(element.uva), "-", parseFloat(element.uva_));
            if (item.oro == undefined) {
              item.oro = 0;
            }
            if (parseFloat(element.uva) > 0) {
              item.uva = element.uva;
              item.uva_ = element.uva_;
              item.oro += parseFloat(item.uva);
            }
            else if(parseFloat(element.ps) > 0) {
              item.ps = element.ps;
              item.ps_ = element.ps_;
              item.oro = parseFloat((parseFloat(item.oro) + parseFloat(item.ps)).toFixed(2));
            }
            else if(parseFloat(element.ph) > 0) {
              item.ph = element.ph;
              item.ph_ = element.ph_;
              item.oro = parseFloat((parseFloat(item.oro) + parseFloat(item.ph)).toFixed(2));
            }
            else if(parseFloat(element.pm) > 0) {
              item.pm = element.pm;
              item.pm_ = element.pm_;
              item.oro = parseFloat((parseFloat(item.oro) + parseFloat(item.pm)).toFixed(2));
              // item.oro += parseFloat(item.pm);
            }
          }
        });
      });

      this.samplings = this.currentItems = data;
      this.loading = false;
    },

    async getProviders(){
      await axios.get(this.db + 'partners',
        {
          headers: this.headers_db
        }).then(response => {
          this.providers = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getProviders(); })
        });
    },

    async getTypes(){
      await axios.get(this.db + 'types',
        {
          headers: this.headers_db
        }).then(response => {
          this.types = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getTypes(); })
        });
    },

    async getCustomers(){
      await axios.get(this.db + 'details/'+ this.id_partner,
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.customers = [];
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },

    addCustomer(payload){
      axios.post(this.db + 'detail/add', {
        id_partner: this.id_partner,
        name: payload.name,
        oro: payload.oro,
        comment: payload.comment,
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getCustomers();
      })
      .catch(function (error) {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.addCustomer(payload); })
      });

    },

    updateCustomer(payload){

      axios.post(this.db + 'detail/update', {
        id: payload.id,
        name: payload.name,
        oro: payload.oro,
        comment: payload.comment,
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getCustomers();
      })
      .catch(function (error) {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.updateCustomer(payload); })
      });

    },

    deleteCustomer(id){

      axios.post(this.db + 'detail/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.customers = this.customers.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.deleteCustomer(id); })
      });

    },
    // FIN Metodos base de datos

    // Metodos Generales

    editItem(item) {
      console.log(item);
      this.editedIndex = this.customers.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    sumField(key) {
      return this.customers.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    getByCustomer() {
      console.log(this.id_provider, this.samplings);
      if (this.id_provider) {
        this.currentItems = this.samplings.filter(doc => {
          return doc.codprov == this.id_provider;
        });
      }
      else {
        this.currentItems = this.samplings;
      }
    },

    addList(item) {
      console.log(item);
      this.id_partner = item.codprov;
      this.total_oro = item.oro
      this.getCustomers();
      this.dialogList = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      }, 300);
    },

    save() {
      
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.providers[this.editedIndex], this.editedItem);
          this.updateCustomer(this.editedItem);
        } else {
          this.providers.push(this.editedItem);
          this.addCustomer(this.editedItem);
        }
        this.close();
      }
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async getAll() {
      this.loader = 'loading3'
      this.loading = true;
      await this.getTypes();
      this.getSamplings();
      this.getProviders();
    },
    eliminar() {
      this.deleteCustomer(this.editedItem.id);
      this.close();
    }
  },
  async mounted() {
    this.loading = true;
    await this.getTypes();
    this.getSamplings();
    this.getProviders();
  },
  filters: {
    formatNumber: function(value) {
      if (!value) return "";
      return new Intl.NumberFormat().format(value);
    },
  },
};
</script>

<style lang="scss">
  .totales {
    font-size: medium !important;
  }
</style>
